import axios from "axios";
import router from "./router";

let api = axios.create({
    baseURL: "/api",
    headers:{
        "Content-type": 'application/json',
    }
});


// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
      // Check if the error is due to unauthorized access or any other specific condition
      if (error.response && error.response.status === 403) {
        // Redirect the user to the home page or login page
        router.push({ name: "Login" }); // Change "home" to your actual home route name
      }

      // Propagate the error to the next catch
      return Promise.reject(error);
    }
  );
  
  export default api;
